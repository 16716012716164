import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import storage from "services/storage";
import api from "services/api";

const LoginSSID = (props) => {
  const [loading, setLoading] = useState(false);

  const loginSSID = async () => {
    setLoading(true);
    const response = await api.sgu.login();
    if (response.success) {
      localStorage.setItem("data", JSON.stringify(response));
      if (window.FreshworksWidget) {
        window.FreshworksWidget("identify", "ticketForm", {
          name: response.user?.name,
          email: response.user?.email,
        });
      }
      props.history.push("/app/blog");
    } else {
      window.location.href = "https://mi.sej.jalisco.gob.mx/?servicio=https://mimuro.jalisco.gob.mx";
    }
    setLoading(false);
    await api.notifications.setFirebaseToken(storage.getFirebaseToken())
  };

  useEffect(() => {
    loginSSID();
  }, []);

  return <div className="app-wrapper">
    <div className="loader-view text-center w-100">
      {
        loading &&
        <CircularProgress />
      }
    </div>
  </div>;
};

export default withRouter(LoginSSID);
