import React, { useState } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import storage from "services/storage";

import configureStore, { history } from "./store";
import App from "./containers/App";


export const store = configureStore();

const MainApp = () => {
  (() => {

    console.log("ssid id")
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ssid = urlParams.get("ssid");
    console.log(ssid)
    if (ssid != null) {
      storage.removeSSID();
      storage.removeToken();
      storage.setSSID(ssid);
    }
  })();

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
};

export default MainApp;
