

// firebase.js
import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import api from "services/api";
import storage from "services/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID 
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();
    
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // Get the FCM token
      const token = await getToken(messaging);
      console.log('FCM Token:', token);
      if(token)
      //  await api.notifications.setFirebaseToken(token)
      storage.setFirebaseToken(token)
    } else {
      console.log('Notification permission denied.');
    }
    // Handle foreground notifications
    onMessage(messaging, (payload) => {
      const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon:"https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.memuplay.com%2Fes%2Fhow-to-use-mx.gob.jalisco.edu.recreapp-on-pc.html&psig=AOvVaw2mM1xqfF9oHouVYWKgN0LE&ust=1723916803656000&source=images&cd=vfe&opi=89978449&ved=0CBQQjRxqFwoTCPiMidqI-ocDFQAAAAAdAAAAABAE"
  };
      new Notification(notificationTitle, notificationOptions);
      console.log('Foreground Message in config:', payload);
      // Handle the notification or update your UI
    });
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};
export { messaging, setupNotifications };