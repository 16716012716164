const getData = () => {
  return JSON.parse(localStorage.getItem("data"));
};

const getFetchingToken = () => {
  let fetchingToken,
    data = null;
  try {
    data = JSON.parse(localStorage.getItem("data"));
    if (!!data) {
      fetchingToken = !!data.fetchingToken;
    }
  } catch (e) {
    fetchingToken = false;
  }
  return fetchingToken;
};

const setFetchingToken = (fetchingToken) => {
  let data = null;
  try {
    data = JSON.parse(localStorage.getItem("data"));
    if (!!data) {
      data.fetchingToken = fetchingToken;
      setStorage(data);
    }
  } catch (e) {
    data = "";
  }
};

const getToken = () => {
  let token,
    data = null;
  try {
    data = JSON.parse(localStorage.getItem("data"));
    if (!!data) {
      token = data.token.access_token ? data.token.access_token : false;
    }
  } catch (e) {
    data = "";
  }
  return token;
};

const getRefreshToken = () => {
  let token,
    data = null;
  try {
    data = JSON.parse(localStorage.getItem("data"));
    if (!!data) {
      token = data.token.refresh_token ? data.token.refresh_token : false;
    }
  } catch (e) {
    data = "";
  }
  return token;
};

const getUserRole = () => {
  let role,
    data = null;
  try {
    data = JSON.parse(localStorage.getItem("data"));
    if (!!data) {
      role = data?.user?.rol;
    }
  } catch (e) {
    data = "";
  }
  return role;
};

const setUser = (user) => {
  const data = JSON.parse(localStorage.getItem("data"));
  data.user = user;
  setStorage(data);
};

const setProceduresIds = (ids) => {
  const data = JSON.parse(localStorage.getItem("data"));
  data.user.procedures_ids = ids;
  setStorage(data);
};

const updateToken = (token) => {
  const data = JSON.parse(localStorage.getItem("data"));
  data.token = token;
  setStorage(data);
  setFetchingToken(false);
};

const setStorage = (data) => {
  localStorage.setItem("data", JSON.stringify(data));
};

const removeToken = () => {
  let data = JSON.parse(localStorage.getItem("data"));
  if (!!data) {
    data = "";
  }
  setFetchingToken(false);
  return setStorage(data);
};

const getSurvey = () => {
  let satisfaction_survey,
    data = null;
  try {
    data = JSON.parse(localStorage.getItem("data"));
    if (!!data) {
      satisfaction_survey = data.user.satisfaction_survey;
    }
  } catch (e) {
    data = "";
  }
  return satisfaction_survey;
};

const setSurvey = (satisfaction_survey) => {
  let data = null;
  try {
    data = JSON.parse(localStorage.getItem("data"));
    if (!!data) {
      data.user.satisfaction_survey = satisfaction_survey;
      setStorage(data);
    }
  } catch (e) {
    data = "";
  }
};

const getProcedureLevelsShown = () => {
  let satisfaction_survey,
    data = null;
  try {
    data = JSON.parse(localStorage.getItem("data"));
    if (!!data) {
      satisfaction_survey = data.procedure_levels_shown;
    }
  } catch (e) {
    data = "";
  }
  return satisfaction_survey;
};

const setProcedureLevelsShown = (procedure_levels_shown) => {
  let data = null;
  try {
    data = JSON.parse(localStorage.getItem("data"));
    if (!!data) {
      data.procedure_levels_shown = procedure_levels_shown;
      setStorage(data);
    }
  } catch (e) {
    data = "";
  }
};

const getSocialsurveyShown = () => {
  let satisfaction_survey,
    data = null;
  try {
    data = JSON.parse(localStorage.getItem("data"));
    if (!!data) {
      satisfaction_survey = data.social_survey_shown;
    }
  } catch (e) {
    data = "";
  }
  return satisfaction_survey;
};

const setSocialSurveyShown = (social_survey_shown) => {
  let data = null;
  try {
    data = JSON.parse(localStorage.getItem("data"));
    if (!!data) {
      data.social_survey_shown = social_survey_shown;
      setStorage(data);
    }
  } catch (e) {
    data = "";
  }
};

const getSSID = () => {
  const { SSID_KEY } = require("constants/ssid");
  return localStorage.getItem(SSID_KEY);
};

const setSSID = (ssid) => {
  const { SSID_KEY } = require("constants/ssid");
  localStorage.setItem(SSID_KEY, ssid);
};

const setFirebaseToken = (token) => {
  localStorage.setItem("Firebase", token);
};

const getFirebaseToken = () => {
  return localStorage.getItem("Firebase");
};

const removeSSID = () => {
  const { SSID_KEY } = require("constants/ssid");
  localStorage.removeItem(SSID_KEY);
};

// const setNotificationsIds = ids => {
//   const data = JSON.parse(localStorage.getItem("data"));
//   data.user.procedures_ids = ids;
//   setStorage(data);
// };

export default {
  getData,
  getFetchingToken,
  setFetchingToken,
  getToken,
  getRefreshToken,
  getUserRole,
  setUser,
  updateToken,
  setStorage,
  removeToken,
  getSurvey,
  setSurvey,
  getProcedureLevelsShown,
  setProcedureLevelsShown,
  getSocialsurveyShown,
  setSocialSurveyShown,
  setProceduresIds,
  getSSID,
  setSSID,
  removeSSID,
  setFirebaseToken,
  getFirebaseToken,
};
